import React from "react"
import Title from "./Title"
import { FaFileDownload } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allStrapiSkills(sort: { fields: order, order: DESC }) {
      nodes {
        order
        label
        desc {
          id
          course
          school
          date
          diploma {
            publicURL
          }
        }
      }
    }
  }
`

const Skills = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiSkills: { nodes: skills },
  } = data
  const [value, setValue] = React.useState(0)
  const { label, desc } = skills[value]
  return (
    <section className="section jobs bg-white">
      <div className="section-container">
        <Title title="Estudios" />
        <div className="jobs-center">
          {/* btn container */}
          <div className="btn-container">
            {skills.map((item, index) => {
              return (
                <button
                  key={index}
                  className={index === value ? "job-btn active-btn" : "job-btn"}
                  onClick={() => setValue(index)}
                >
                  {item.label}
                </button>
              )
            })}
          </div>
          {/* job info */}
          <article className="skill-info">
            {desc.map(item => {
              return (
                <div key={item.id} className="skill-desc">
                  <div className="skill-date">
                    <p>{item.date}</p>
                  </div>
                  <div className="skill-title">
                    <a href={item.diploma.publicURL} target="_blank">
                      <h5>{item.course}</h5>
                    </a>
                    <p>{item.school}</p>
                  </div>
                  <div className="skill-doc">
                    <a href={item.diploma.publicURL} target="_blank">
                      <FaFileDownload />
                    </a>
                  </div>
                </div>
              )
            })}
          </article>
        </div>
      </div>
    </section>
  )
}

export default Skills
