import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import Jobs from "../components/Jobs"
import Skills from "../components/Skills"
import Title from "../components/Title"
import Seo from "../components/Seo"
import Contact from "../components/Contact"
import ReactFullpage from "@fullpage/react-fullpage"
import { FaEnvelopeSquare , FaWhatsapp, FaLinkedin, FaInstagram, FaBehance, } from "react-icons/fa"

const AboutPage = ({
  data: {
    strapiAbout: { title, image, info, stack },
  },
}) => {
  return (
    <ReactFullpage
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <>
          <Seo title="Sobre mí" />
          <ReactFullpage.Wrapper>
            <div className="section">
              <div className="section-container about-center">
                <Img fluid={image.childImageSharp.fluid} alt={title} className="about-img"/>
                <article className="about-text">
                  <Title title={title} />
                  <p>{info}</p>
                </article>
              </div>
            </div>
            <Skills className="section" />
            <Jobs className="section" />
            <Contact className="section" />
          </ReactFullpage.Wrapper>
          </>
        )
      }}
    />
  )
}

export const query = graphql`
  {
    strapiAbout {
      title
      info
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allStrapiSkills(sort: { fields: order, order: DESC }) {
      nodes {
        order
        label
        desc {
          id
          course
          school
          date
          diploma {
            publicURL
          }
        }
      }
    }
  }
`

export default AboutPage